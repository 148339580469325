import { ClipboardEvent, FC, KeyboardEvent, useCallback, useEffect, useState } from 'react';
import styles from './PinControl.module.scss';
import { isDigitSymbol } from './../../../../../helpers/is-digit-symbol.helper.ts';
import { observer } from 'mobx-react-lite';

interface IPinControlProps {
  length?: number;
  onChange?: (value: string) => void;
  className?: string;
}

export const PinControl: FC<IPinControlProps> = observer(({ length = 6, onChange, className }) => {
  const [values, setValues] = useState(Array(length).fill(''));
  
  const toNextControl = useCallback((currentIndex: number) => {
    if (currentIndex < length) document.getElementById(`pin-${currentIndex + 1}`)?.focus();
  }, []);
  
  const toPrevControl = useCallback((currentIndex: number) => {
    if (currentIndex > 0) document.getElementById(`pin-${currentIndex - 1}`)?.focus();
  }, []);
  
  const updateValueControl = useCallback((value: string, index: number) => {
    setValues((prevState) => {
      const newValues = [...prevState];
      newValues[index] = value;
      return newValues;
    });
  }, []);
  
  useEffect(() => { if (onChange) onChange(values.join('')) }, [values]);

  const handleInput = useCallback((e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const value = e.target.value.replace(/\D/g, '');
    if (value) {
      updateValueControl(value, index);
      toNextControl(index);
    }
  }, []);

  const handleKeyDown = useCallback((e: KeyboardEvent, index: number) => {
    if (e.key.toLowerCase() === 'backspace') {
      e.preventDefault();
      updateValueControl('', index);
      toPrevControl(index);
      return;
    }
    
    if ((e.key.toLowerCase() === 'tab' || e.key.toLowerCase() === 'space') && index < length) {
      e.preventDefault();
      toNextControl(index);
      return;
    }
    //
    // if (isDigitSymbol(e.key)) {
    //   updateValueControl(e.key, index);
    //   toNextControl(index);
    // }
  }, []);
  
  const handlePaste = useCallback((e: ClipboardEvent) => {
    const paste = e.clipboardData.getData('text').replace(/\D/g, '');
    if (paste.length > 0) {
      let startIndex = Number(document.activeElement?.id?.split('-')[1] ?? 0);
      setValues((values) => {
        const newValues = [...values];
        paste.split('').forEach((char) => {
          if (startIndex < length) {
            newValues[startIndex] = char;
            startIndex++;
          }
        });
        return newValues;
      });
      toNextControl(startIndex);
    }
  }, []);
  
  return (
    <div className={styles.InputControls}>
      {values.map((value, index) => (
        <input
          id={`pin-${index}`}
          key={index}
          type="text"
          value={value}
          maxLength={1}
          inputMode="numeric"
          pattern="[0-9]*"
          onKeyDown={(e) => handleKeyDown(e, index)}
          onInput={(e) => handleInput(e as React.ChangeEvent<HTMLInputElement>, index)}
          onPaste={handlePaste}
          className={styles.InputControl}
        />
      ))}
    </div>
  );
});
