import { isPhysicalCard } from '../../../../../../../helpers/card-kind.helper.ts';
import classNames from 'classnames';
import styles from '../../TopUpCard.module.scss';
import Loader from '../../../../../../../components/Loader';
import { CardType } from '../../../../../../../services/card-api';
import { ReactNode } from 'react';
import cn from 'classnames';

interface IProps {
  cardType?: CardType,
  exchangeRate?: number | null,
  isLoadingExchangeRate?: boolean,
  className?: string,
}

const minTopUpLimitVirtualCard = 50;
const maxTopUpLimitVirtualCard = 10000;
const minTopUpLimitPhysicalCard = 50;
const maxTopUpLimitPhysicalCard = 100000;

export const TopUpSectionInfo: (props: IProps) => ReactNode = (
  {
    cardType,
    isLoadingExchangeRate,
    exchangeRate,
    className,
  }
) => {
  return (
    <div className={cn('tw-flex tw-flex-col tw-gap-2.5 xl:tw-gap-4.5', className)}>
      {isPhysicalCard(cardType) &&
        <div className={classNames(styles.BlockInfo, 'tw-flex tw-gap-4 xl:tw-gap-4.5 tw-bg-secondary-75')}>
          <div>
            <img
              src="/images/exchange-in-green-container-icon.svg"
              alt="Exchange icon"
              className={styles.InfoIcon}
            />
          </div>
          <div className="">
            <span className="tw-text-xs tw-text-secondary xl:tw-text-sm">Exchange rate</span>
            <div className="tw-text-base tw-mt-1.5 xl:tw-text-2xl xl:tw-mt-2">
              {isLoadingExchangeRate
                ? <div className="tw-flex-centering tw-h-9"><Loader/></div>
                : `1 USDT = ${exchangeRate} HKD`
              }
            </div>
          </div>
        </div>
      }
      <div
        className={classNames(styles.BlockInfo, 'tw-flex tw-gap-4 xl:tw-gap-4.5 tw-bg-secondary-75')}>
        <div>
          <img
            src="/images/percent-in-green-container-icon.svg"
            alt="Percent icon"
            className={styles.InfoIcon}
          />
        </div>
        <div>
          <span className="tw-text-xs xl:tw-text-sm tw-text-secondary">Top up fee</span>
          <div className="tw-text-base tw-mt-1.5 xl:tw-mt-2 xl:tw-text-2xl">2.2%</div>
        </div>
      </div>
      <div
        className={classNames(styles.TopUpLimitsBlockInfo, 'tw-flex tw-gap-4 xl:tw-gap-4.5 tw-bg-secondary-75 tw-text-sm')}>
        <div>
          <img
            src="/images/information-icon-with-red-container.svg"
            alt="Alert icon"
            className={styles.InfoIcon}
          />
        </div>
        <div>
          <span className="tw-text-secondary">Top up limits</span>
          <div className="tw-mt-1.5 xl:tw-mt-2">
            <p>
              The following top-up limits apply at VPlus to maintain <br/>
              a secure and efficient transaction process. Please take <br/>
              a moment to review the details: <br/>
            </p>
          </div>
          <div className="tw-mt-4">
            <div className="tw-flex">
              <span>Minimum Top Up Amount:</span>
              <span className="tw-ml-0.5">
                {
                  (isPhysicalCard(cardType)
                    ? minTopUpLimitPhysicalCard
                    : minTopUpLimitVirtualCard).toLocaleString('en-us')
                } USDT
              </span>
            </div>
            <div>
              <span>Maximum Top Up Amount:</span>
              <span className="tw-ml-0.5">
                {
                  (isPhysicalCard(cardType)
                    ? maxTopUpLimitPhysicalCard
                    : maxTopUpLimitVirtualCard).toLocaleString('en-us')
                } USDT per day
                </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};
