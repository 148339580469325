import { ICardBalanceDto, ICardDataDto, ICardDto, ICardTransactionDto } from './interfaces';
import { IDepositDto } from './interfaces/deposit.interface.ts';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { detailCardStatusToCardStatusMap } from './consts';
import { apiClient, IAxiosRequestConfig } from '../../helpers/apiClient.ts';
import { showSuccessToast } from "../../helpers/toasts.helper.ts";

export class CardApiService {
  public async getCards(updateCardsStatuses: boolean = true): Promise<ICardDto[]> {
    const response = await apiClient.get<{cards: ICardDto[]}>('/cards');
    const cards = response.data.cards ?? [];
    if (!updateCardsStatuses) return cards;
    return this._updateCardsStatuses(cards);
  }
  
  public async getTransactions(cardId: string, config?: IAxiosRequestConfig): Promise<ICardTransactionDto[]> {
    const response = await apiClient.get<{ transactions: ICardTransactionDto[]}>(
      `/cards/${cardId}/transactions`,
      { ...config }
    );
    return response.data.transactions ?? [];
  }

  public async getBalance(cardId: string, config?: AxiosRequestConfig): Promise<ICardBalanceDto | null> {
    const response = await apiClient
      .get<ICardBalanceDto, AxiosResponse<ICardBalanceDto>>(`/cards/${cardId}/balance`, { ...config });
    return response.data ?? null;
  }

  public async getDetailsCardData(cardId: string, config?: AxiosRequestConfig): Promise<ICardDataDto | null> {
    const response = await apiClient.get<ICardDataDto, AxiosResponse<ICardDataDto>>(`/cards/${cardId}`, { ...config });
    return response.data ?? null;
  }


  public async lockCard(cardId: string): Promise<void> {
    try {
      await apiClient.post(`/cards/${cardId}/freeze`, null);
      showSuccessToast('Card successfully locked!');
    } catch (error) {
      console.error('Error locking card:', error);
      throw error;
    }
  }

  public async unlockCard(cardId: string): Promise<void> {
    try {
      await apiClient.post(`/cards/${cardId}/unfreeze`, null);
      showSuccessToast('Card successfully unlocked!');
    } catch (error) {
      console.error('Error unlocking card:', error);
      throw error;
    }
  }

  public async setCardPin(cardId: string, pin: string): Promise<void> {
    try {
      await apiClient.post(
        `/cards/${cardId}/pin`,
        { pin }
      );
      showSuccessToast('Pin was successfully changed!');
    } catch (error) {
      console.error('Error setting PIN:', error);
      throw error;
    }
  }
  
  public async getDeposit(cardId: string): Promise<IDepositDto> {
    const { data } = await apiClient.post(`/cards/${cardId}/deposit`) as AxiosResponse<IDepositDto>;
    return data;
  }
  
  private async _updateCardsStatuses(inputCards: ICardDto[]): Promise<ICardDto[]> {
    const cards = inputCards.map(c => ({ ...c }));
    const requests = cards
      .reduce((res, { id }) => {
        res.push(cardApiService.getDetailsCardData(id) as Promise<ICardDataDto>);
        return res;
      }, [] as Promise<ICardDataDto>[]);
    
    try {
      const results = await Promise.allSettled(requests);
      results.forEach((result) => {
        if (result.status === 'fulfilled') {
          const findCard = cards.find(c => c.cardType === result.value.cardType);
          if (findCard) findCard.status = detailCardStatusToCardStatusMap[result.value.status] ?? findCard.status;
        }
      });
      return cards;
    } catch {
      return cards;
    }
  }
}

export const cardApiService: CardApiService = new CardApiService();
