import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import styles from './CardTransactions.module.scss';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  getOperationSign,
  getOperationTypeText,
  isInflowOperation,
  isOutFlowOperation,
  transactionTextByStatusMap
} from './../../../../../helpers/transaction.helper.ts';
import { format } from 'date-fns';
import formatBalance from './../../../../../helpers/formatBalance.ts';
import { convertCentsToCurrency } from './../../../../../helpers/convert-cents-to-currency.helper.ts';
import Loader from '../../../../../components/Loader';
import Icon from '../../../../../components/Icon';
import { Tooltip } from 'react-tooltip';
import { ICardTransactionDto } from '../../../../../services/card-api';

interface ICardTransactionProps {
  cardId?: string;
  isLoading?: boolean;
  transactions?: ICardTransactionDto[];
  className?: string;
}

export const CardTransactions: FC<ICardTransactionProps> = observer(({ cardId, isLoading, transactions, className }) => {
  const navigate = useNavigate();

  return (
    <div className={cn(styles.transactionWrapper, className)}>
      <header className="tw-flex tw-justify-between tw-items-center tw-gap-5">
        <h2 className="tw-flex-centering tw-gap-1 xl:tw-text-1.5xl xl:tw-gap-1.5">
          Recent transactions
          <Icon
            id="recent-transaction-info"
            className="tooltip-icon tw-size-4.5 xl:tw-size-5.5 tw-cursor tw-cursor-pointer hover:tw-stroke-secondary"
            icon="info"
          />
          <Tooltip
            anchorSelect="#recent-transaction-info"
            place="bottom-start"
          >
            Card transactions may appear <br/>
            with a slight delay of up to 24 hours. <br/>
            If you don't see recent transactions, don't worry – the information will update soon.
          </Tooltip>
        </h2>
        <button
          className={styles.ViewHistoryBtn}
          disabled={!cardId}
          onClick={() => navigate(`${cardId}/transactions`)}
        >
          View History
        </button>
      </header>
      {!isLoading && !!transactions?.length && (
        <div className={styles.Transactions}>
          {transactions.map(({ id, type, amount, status, time, currency, localAmount, localCurrency }, index) => (
            <div key={id || index} className="tw-flex tw-justify-between tw-items-center tw-gap-4 xl:tw-gap-5">
              <div className="tw-flex tw-flex-col tw-gap-1">
                <div>{getOperationTypeText(type)}</div>
                <div className="tw-text-secondary tw-text-2sm tw-font-medium">{format(new Date(time), 'dd-MM-yyyy')}</div>
              </div>
              <div className="tw-flex tw-flex-col tw-gap-1 tw-text-end">
                <div className={cn(
                  isInflowOperation(type) && 'tw-text-teal',
                  isOutFlowOperation(type) && 'tw-text-danger',
                )}>
                  {localAmount && localCurrency && localAmount !== 0 ? (
                    <>
                      {getOperationSign(type)} {formatBalance(convertCentsToCurrency(localAmount))} {localCurrency}
                      {localAmount !== amount && localCurrency !== currency && (
                        <div className={styles.TransactionCurrency}>
                          {getOperationSign(type)} {formatBalance(convertCentsToCurrency(amount))} {currency}
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {getOperationSign(type)} {formatBalance(convertCentsToCurrency(amount))} {currency}
                    </>
                  )}
                </div>
                <div className="tw-text-secondary tw-text-2sm tw-font-medium">{transactionTextByStatusMap[status]}</div>
              </div>
            </div>
          ))}
        </div>
      )}
      {isLoading && (
        <div className="tw-flex-centering tw-flex-1">
          <Loader />
        </div>
      )}
      {!isLoading && !transactions?.length && (
        <div className={cn(styles.noTransactions, 'tw-flex-centering tw-flex-1 xl:tw-text-2.25xl tw-text-secondary-550')}>
          <p className="tw-text-center">You have not made any transactions yet</p>
        </div>
      )}
    </div>
  );
});
